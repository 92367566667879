* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body,
#root {
  height: 100%;
}

body {
  font-family: sans-serif;
  overflow-x: hidden;
}

img {
  max-width: 100%;
}

.main-screen {
  margin-bottom: 60px;
}

.owl-theme .item {
  min-height: 100%;
  min-height: 100vh;
}

.rsc-content {
  min-height: 86%;
  min-height: 86vh;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-leave {
  opacity: 1;
}

.fade-leave.fade-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

